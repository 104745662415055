export const DATA = [
  {
    study: 1,
    project:
      "Proteome analysis of glandular parotid and submandibular-sublingual saliva in comparison to whole human saliva by two-dimensional gel electrophoresis",
    disease: "Disease Free",
    institution: "University of Regensburg, Germany",
    year: "2006",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/16402355.csv.gz",
    available: true,
  },
  {
    study: 2,
    project:
      "The proteomes of human parotid and submandibular/sublingual gland salivas collected as the ductal secretions",
    disease: "Disease Free",
    institution: "University of California, Los Angeles",
    year: "2008",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/18361515.csv.gz",
    available: true,
  },
  {
    study: 3,
    project:
      "Alterations in the Salivary Proteome and N-Glycome of Sjögren's Syndrome Patients",
    disease: "Sjögren's Syndrome",
    institution: "University of California, San Francisco​",
    year: "2017",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/28282148.csv.gz",
    available: true,
  },
  {
    study: 4,
    project:
      "Cross-Sectional Association of Salivary Proteins with Age, Sex, Body Mass Index, Smoking, and Education",
    disease: "Disease Free",
    institution: "University Medicine Greifswald",
    year: "2017",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/28481548.csv.gz",
    available: true,
  },
  {
    study: 5,
    project:
      "Self-Assembled STrap for Global Proteomics and Salivary Biomarker Discovery",
    disease: "Oral Squamous Cell Carcinoma",
    institution: "J. Craig Venter Institute",
    year: "2019",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/30848925.csv.gz",
    available: true,
  },
  {
    study: 6,
    project:
      "Mapping Relative Differences in Human Salivary Gland Secretions by Dried Saliva Spot Sampling and nanoLC-MS/MS",
    disease: "Disease Free",
    institution: "The Forsyth Institute",
    year: "2019",
    download:
      "https://s3.amazonaws.com/salivaryproteome.org/hspw-prod-public-data/31476108.csv.gz",
    available: true,
  },
  {
    study: 7,
    project:
      "Persistent Immune and Clotting Dysfunction Detected in Saliva and Blood Plasma after COVID-19",
    disease: "COVID-19",
    institution: "J. Craig Venter Institute",
    year: "2022",
    download:
      "https://drive.google.com/file/d/1xfHuPKmEP-1-0b0E9qKIf-bNdxwJhayv/view?usp=sharing",
    available: true,
  },
];
